import React, { useEffect, useState } from 'react';
import './App.css';
import { Player, Show, Stories } from 'siempre-hay-components';
import ShowJSON from "./data/show.json";
import SeasonJSON from "./data/seasons.json";
import { useSelector } from 'react-redux';
import { useDispatch } from "react-redux";
// @ts-ignore
import { setPlayer } from "siempre-hay-redux/playerSlice";

function App() {

  const player = useSelector((state: any) => state.player);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPlayer({ isPlaying: false, episode: {} }))
  }, []);

  useEffect(() => {
    console.log(player);
  }, [player]);

  return (
    <div className="App">
      {player.isPlaying && <Player episode={player.episode} isPlaying={player.isPlaying} />}
      {!player.isPlaying && <>
        <Stories stories={[{
          type: 'video',
          url: "https://firebasestorage.googleapis.com/v0/b/proyecto-sin-titulo-25546.appspot.com/o/The_IT_Crowd_Return_Of_The_Golden_Child_Full_Episo.mp4?alt=media&token=bbc292a4-bc2d-453e-8e60-2b19c6250af3",
          duration: 76000
        }, {
          type: 'video',
          url: "https://firebasestorage.googleapis.com/v0/b/proyecto-sin-titulo-25546.appspot.com/o/The_IT_Crowd_Return_Of_The_Golden_Child_Full_Episo%20(1).mp4?alt=media&token=094c7f2c-cc48-41c1-87ae-bacb600b3907",
          duration: 60000
        }]} />
        <Show show={ShowJSON} seasons={SeasonJSON} />
      </>}
    </div>
  );
}

export default App;
